/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import { IconButton } from "@material-ui/core";

// @material-ui/core icons

// core components
import Button from "core/components/CustomButtons/Button";
import vector from "core/assets/img/explore-vector.png";

import styles from "core/assets/jss/material-dashboard-pro-react/components/exploreStyle.js";
const useStyles = makeStyles(styles);

export default function Explore(props) {
  const classes = useStyles();
  const { fluid, white, base="website" } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  return (
    <footer className={classes.footer}>
      <div style={{backgroundImage: "url(" + vector + ")", 
                    backgroundRepeat: "no-repeat" }}>
        <div className={container}>
          <div className={classes.left}>
            <h2 className={classes.leftHeading}>Start using Exaspaces!</h2>
            <p className={classes.leftText}>To manage your Real Estate Business in a Seamless, Simplified and Transformative way!</p>
          </div>
          <div className={classes.right}>
            <Button
              color="white" 
              className={classes.button}
              onClick={()=>{props.history.push(`/${base}/signup`)}}>
                Get started
            </Button>
          </div>
        </div>
      </div>
    </footer>
  );
}

Explore.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
};
