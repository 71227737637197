import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "core/components/Grid/GridContainer.js";
import GridItem from "core/components/Grid/GridItem.js";
import Button from "core/components/CustomButtons/Button.js";
import InfoArea from "core/components/InfoArea/InfoArea.js";
import Card from "core/components/Card/Card.js";
import CardBody from "core/components/Card/CardBody.js";
import CardFooter from "core/components/Card/CardFooter.js";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

import backgroundImage from "assets/img/login-background.jpeg";

import styles from "core/assets/jss/material-dashboard-pro-react/views/registerPageStyle";
const useStyles = makeStyles(styles);

export default function ResultView(props) {
	const { dashboard=false } = props;

  // animation for the card entry
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 400);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

	const backClick = () => {
		if(props.onChangeHandler) {
			props.onChangeHandler(false, null, 'changepassword', null);
		}
	}

	const classes = useStyles();
	return (
		<div>
			<div className={!dashboard ? classes.fullPage : classes.container}
					style={!dashboard ? {
						backgroundImage: "url(" + backgroundImage + ")",
						backgroundSize: "cover",
						backgroundPosition: "center"
						} : null}>
				<div className={classes.container}>
					<GridContainer justifyContent="center">
						<GridItem xs={12} sm={6} md={5}>
							<form>
								<Card login className={classes[cardAnimaton]}>
									<CardBody>
										<GridContainer justifyContent="center">
											<GridItem xs={12} sm={12} md={10}>
												<InfoArea
													title={props.infotitle}
													description={props.infosubtitle}
													icon={Check}
													iconColor="rose"
												/>
												</GridItem>
										</GridContainer>
									</CardBody>
									<CardFooter>
										<GridContainer justifyContent="center">
											<GridItem xs={12} sm={12} md={6}>
												<div className={classes.center}>
													{!dashboard ?
														<Button
															fullWidth round color="purple"
															onClick={() => props.history.push("/website")}>
																Back Home
														</Button> :
														<Button 
															fullWidth round color="primary"
															onClick={backClick}>
																Back
														</Button>
													}
													<p></p>
												</div>
											</GridItem>
										</GridContainer>
									</CardFooter>
								</Card>
							</form>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
  )    
}