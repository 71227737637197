import {
  hexToRgb,
  blackColor,
  grayColor,
} from "core/assets/jss/material-dashboard-pro-react.js";

const infoDetailSectionStyles = theme => ({
  section: {
    color: blackColor,
    display: "flex",
    minHeight: "380px",
    marginTop: "4px",
    marginBottom: "4px",
  },
  title: {
    fontSize: "1.995em",
    fontWeight: "700",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.795em",
    },
  },
  description: {
    fontSize: "1.2em",
    fontWeight: "400",
  },
  knowMoreButton: {
    marginTop: "24px",
    fontWeight: "500",
  },
  textViewBackground: {
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "contain",
    },
  },
  imageViewBackground: {
    padding: "32px 24px",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "contain",
      maxWidth: "90%",
    },
  },
  image: {
    transform: "translate(7%, 0%)",
    maxWidth: "380px",
    borderStyle: "solid",
    borderColor: grayColor[10],
    borderTop: "1px",
    borderRadius: "5px",
    verticalAlign: "middle",
    boxShadow:
    "0 16px 24px 2px rgba(" +
    hexToRgb(blackColor) +
    ", 0.14), 0 6px 30px 5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)",
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "540px",
    },
  },  
});

export default infoDetailSectionStyles;