import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { WhatsApp } from "@material-ui/icons";
import Call from "@material-ui/icons/Call";

const styles = {
  display: "flex",
  alignItems: "center",
  sidenav: {
    overflow: "hidden",
    "& a": {
      position: "fixed",
      right: "-164px",
      width: "200px",
      transition: "0.3s",
      padding: "8px 16px 8px 8px",
      textDecoration: "none",
      fontSize: "20px",
      color: "white",
      borderRadius: "5px 0 0 5px",
      zIndex: "1031",
    },

    "& a:hover": {
      right: "0px",
    },
  },
  
  whatsapp: {
    bottom: "120px",
    backgroundColor: "#04AA6D",
  },

  mobile: {
    bottom: "180px",
    backgroundColor: "#2196F3",
  },

  content: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
  },
  textContent: {
    paddingStart: "8px"
  },
  topline : {
    fontSize: "15px",
    fontWeight: "500",
    margin: "0px",
  }
};

const useStyles = makeStyles(styles);

export default function CallToAction(props) {
  const { whatsapp=true, mobile=true } = props;

  const WHATSAPP_LINK = `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_API_WHATSAPP_PHONE}`;
  const classes = useStyles();
  return (
    <div id="callToAction" className={classes.sidenav}>
      {whatsapp && 
        <a href={WHATSAPP_LINK} 
          target="_blank" rel="noopener noreferrer"
          className={classes.whatsapp}>
          <span className={classes.content}>
            <WhatsApp /> 
            <div className={classes.textContent}>
              <p className={classes.topline}>WhatsApp</p>{process.env.REACT_APP_API_WHATSAPP}
            </div>
          </span>
        </a>}
        {mobile && 
        <a href={`tel:${process.env.REACT_APP_API_MOBILE}`} 
          className={classes.mobile}>
          <span className={classes.content}>
            <Call /> 
            <div className={classes.textContent}>
              <p className={classes.topline}>Call</p>{process.env.REACT_APP_API_MOBILE}
            </div>
          </span>
        </a>}

    </div>
  );
}

CallToAction.propTypes = {
  whatsapp: PropTypes.bool
};
