import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Hidden, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";

// core components
import GridContainer from "core/components/Grid/GridContainer";
import GridItem from "core/components/Grid/GridItem";

// echelon images
import defaultImage from "assets/img/section-background.png";

import visionSectionStyles from "assets/jss/material-dashboard-pro-react/components/listAndImageAreaStyles";
const useStyles = makeStyles(visionSectionStyles);

export default function ListAndImageArea (props) {
  const { position="center center", alignItems="center", items, backgroundImage=defaultImage } = props;
  const [image, setImage] = React.useState(items[0].image);

  const classes = useStyles();
  return (
    <GridContainer justifyContent="center" alignItems={alignItems} className={classes.gridItem}>
      <GridItem xs={12} sm={12} md={6} lg={5}>
        <List className={classes.listContent}>
          {items.map((item, key) => {
            return (
                <ListItem key={key} dense alignItems="flex-start" button
                  onClick={() => setImage(item.image)}
                  className={image === item.image ? classes.selectedListItem : classes.listItem}>
                {item.icon &&
                  <ListItemIcon className={classes.icon}>
                    <img className={classes.icon} src={item.icon} alt={item.primary} />
                  </ListItemIcon>
                }
                <ListItemText
                  primary={<h2 className={classes.primaryText}>{item.primary}</h2>}
                  secondary={item.secondary} 
                  classes={{secondary: classes.secondaryText}}
                />
                </ListItem>
            )}
          )}
        </List>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={7}>
        <Hidden smDown implementation="css">
          <GridContainer justifyContent="center" alignItems="center">
            <GridItem  xs={12} sm={12} md={12}>
              <div 
                className={classes.backgroundImage} 
                style={{backgroundImage: "url(" + backgroundImage + ")" ,
                        backgroundPosition: position}}>
                <img
                  className={classes.image}
                  src={image} 
                  alt="value proposition" 
                />
              </div>
            </GridItem>
          </GridContainer>
        </Hidden>
      </GridItem>
    </GridContainer>
  );
}

ListAndImageArea.propTypes = {
  position: PropTypes.oneOf([
    "left",
    "right",
    "center",
    "top left",
    "top right",
    "bottom left",
    "bottom right",
  ])
}