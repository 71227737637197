// Imports
import React, { Component } from 'react';
import PropTypes from "prop-types";

// material-ui components
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "core/components/CustomInput/CustomInput.js";

// material-ui icons
import Place from "@material-ui/icons/Place";

//Import React script Libraray to load Google object
import Script from "react-load-script";

class Address extends Component {
  
    // Define Constructor
    constructor(props) {
      super(props);
  
      // Declare State
      this.state = {
        address: {
          line1: '',
          line2: '',
          locality: '',
          areaLevel2: '',
          areaLevel1: '',
          country: '',
          postalCode: '',
        },
        formatted_address: '',
        query: ''
      };
    }
  
    componentDidMount() {
    }

    handleScriptLoad = () => {
        // Declare Options For Autocomplete
        const options = {
          types: this.props.fullAddress === false ? ['(cities)'] : [],
          componentRestrictions: {country: 'IN'}
        };
    
        // Initialize Google Autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new google.maps.places.Autocomplete(
          document.getElementById(this.props.id),
          options,
        );
    
        // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned to just the address components and formatted
        // address.
        this.autocomplete.setFields(['address_components', 'formatted_address']);
    
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
      }

      handlePlaceSelect = () => {
        // Extract City From Address Object
        const addressObject = this.autocomplete.getPlace();
        const components = addressObject.address_components;

        // Check if address is valid
        if(components && components.length > 0) {
          var addressMap = components.reduce(function(map, obj) {
            map[obj.types[0]] = obj;
            return map;
          }, {});

          var line1 = "";
          var index = addressObject.formatted_address.indexOf(components[0].long_name);
          if(index > 0) {
              line1 = addressObject.formatted_address.substring(0, index-1) + " ";
          }

          var separator = "";
          // handle line1 components (premise, street_number, route, neighbourhood)
          var line1components = ['premise', 'street_number', 'route', 'neighbourhood'];
          for (index = 0; index < line1components.length; index++) { 
            const line1component = addressMap[line1components[index]];
            if(line1component) {
              line1 = line1.concat(separator, line1component.long_name);
              separator = ", ";
            }
          }
          separator = "";
 
          var line2 = ""
          // handle line2 components (sublocality_level_2, sublocality_level_1)
          var line2components = ['sublocality_level_2', 'sublocality_level_1'];
          for (index = 0; index < line2components.length; index++) { 
            const line2component = addressMap[line2components[index]];
            if(line2component) {
              line2 = line2.concat(separator, line2component.long_name);
              separator = ", ";
            }
          }

          const locality = addressMap['locality'] ? addressMap['locality'].long_name : null;
          const areaLevel2 = addressMap['administrative_area_level_2'] ? 
                              addressMap['administrative_area_level_2'].long_name : null;
          const areaLevel1 = addressMap['administrative_area_level_1'] ? 
                              addressMap['administrative_area_level_1'].long_name : null;
          const country = addressMap['country'] ? addressMap['country'].long_name : null;
          const postalCode = addressMap['postal_code'] ? addressMap['postal_code'].long_name : null;

          this.setState({ address: {
              line1: line1,
              line2: line2,
              locality: locality,
              areaLevel2: areaLevel2,
              areaLevel1: areaLevel1,
              country: country,
              postalCode: postalCode,
            }
          });
        }
        this.setState({formatted_address: addressObject.formatted_address});

        if(this.props.onPlaceChanged) {
          this.props.onPlaceChanged(this.state.address, this.state.formatted_address);
        }
      }
      
    onChange = (event, attribute) => {
        this.setState({ [attribute]: event.target.value });
    }

    render() {
      return (
        <div>
          <Script
            url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmYeWcEPsItHJHCwCxZS-DtbM6YSNDBeU&libraries=places"
            onLoad={this.handleScriptLoad}
          />
          <CustomInput
              id={this.props.id}
              labelText = {this.props.labelText}
              success = {this.props.success}
              error = {this.props.error}
              placeholder = {this.props.placeholder}
              formControlProps={{
                fullWidth: true,
                className: this.props.formClassName
              }}
              inputProps={{
                ...this.props.inputProps,
                endAdornment: (
                    <InputAdornment position="end">
                      <Place />
                    </InputAdornment>
                ),
                onChange: event => {
                  this.onChange(event, 'formatted_address');
                },
                value: this.state.formatted_address,
                placeholder: this.props.placeholder
              }}
          />
      </div>
    );
  }
}

Address.defaultProps = {
  id: 'autocomplete',
  placeholder: '',
  fullAddress: true
}

Address.propTypes = {
  id: PropTypes.string,
  onPlaceChanged: PropTypes.func,
  formClassName: PropTypes.any,
  labelText: PropTypes.node,
  placeholder: PropTypes.node,
  inputProps: PropTypes.object,
  fullAddress: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
}

export default Address;