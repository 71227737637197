import React from "react";
import { Fragment } from "react";

const RecaptchaButton = React.forwardRef((props, ref) => {
  const { 
    Button,
    onClick,
    purpose="register",
    key="6LcMZiUcAAAAAPNDC-bKt7ecxJB-1R8Vfdy8-647", 
    manualcaptcha=false,
    ...rest } = props;

  const onClickSubmit = (event) => {
    if(window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(key, { action: purpose })
            .then((token) => onClick({ token: token, purpose: purpose, version: 'v3' }));
      });
  
      return;
    }

    onClick(event);
  }

  const verifyCallback = (response) => {
    onClick({ token: response, purpose: purpose, version: 'v2' });
  }

  const [rendered, setRendered] = React.useState(false);
  const showManualRecaptcha = () => {
    if(!rendered) {
      if(window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha.render('manual-recaptcha', {
            'sitekey' : '6LctaikcAAAAAJ4j-I9nLI69ewqUJek85Tvzz10K',
            'callback' : verifyCallback,
          });
          setRendered(true);
        });  
      }
    }
  }

  return (
    <Fragment>
    { manualcaptcha ? (
      <Fragment>
        <div id="manual-recaptcha" style={{justifyContent: "center", display: "flex"}}/>
        {showManualRecaptcha()}
      </Fragment>
    ) : (
      <Button {...rest} onClick={onClickSubmit}/>
    )}
    </Fragment>
  );
});

export default RecaptchaButton;