import React from "react";

// echelon components and icons
import ListAreaSection from "views/Website/components/Section/ListAreaSection";

import backgroundImage from "assets/img/implementation/section2-background.png";
import implementationImage from "assets/img/implementation/section2-implementation.png";
import awarenessImage from "assets/img/implementation/section2-awareness.png";

import implementationIcon from "assets/img/implementation/section2-implementation.svg";
import awarenessIcon from "assets/img/implementation/section2-awareness.svg";

export default function SectionBottom(props) {
  const { position="bottom right" } = props;
  return (
    <ListAreaSection 
      position={position}
      image={backgroundImage}
      items={[
        {
          primary: "Rapid implementation",
          secondary: "A very well-defined approach to enable customers to embrace Exaspaces as quickly as possible",
          icon: implementationIcon,
          image: implementationImage
        },
        {
          primary: "Rapid awareness",
          secondary: "Awareness about the platform is key to any successful adoption. We offer a well-structured program to aid adoption, leading to consumption",
          icon: awarenessIcon,
          image: awarenessImage
        }
      ]}
    />
  );
}