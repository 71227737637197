import {
  blackColor,
} from "core/assets/jss/material-dashboard-pro-react.js";

const listAreaSectionStyles = theme => ({
  section: {
    color: blackColor,
  },
});

export default listAreaSectionStyles;