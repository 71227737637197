import React from "react";

// echelon components and icons
import ListAreaSection from "views/Website/components/Section/ListAreaSection";

import listItemIcon from "assets/img/platform/broker/transaction/section2-properties.svg";

import image1 from "assets/img/platform/broker/transaction/section2-image1.png";
import image2 from "assets/img/platform/broker/transaction/section2-image2.png";

export default function SectionBottom(props) {
  const { position="top left" } = props;

  return (
    <ListAreaSection 
      position={position}
      image={null}
      items={[
        {
          primary: "Foundation for Property data",
          secondary: "Holistic property information with inbuilt governance and access credentials",
          icon: listItemIcon,
          image: image1
        },
        {
          primary: "Multi-dimensional view of property data",
          secondary: "Deep Property information, Infrastructure data, Commercial data (including approvals for Land), Occupancy information and associated unit information",
          icon: listItemIcon,
          image: image2
        },
      ]}
    />
  );
}