// authorize action types
export const AUTHORIZE_START = 'AUTHORIZE_START';
export const AUTHORIZE_SUCCESS = 'AUTHORIZE_SUCCESS';
export const AUTHORIZE_FAILURE = 'AUTHORIZE_FAILURE';
export const AUTHORIZE_LOGOUT = 'AUTHORIZE_LOGOUT';

// clear state
export const CLEAR_PASSWORD_STATE = "CLEAR_PASSWORD_STATE";

// request reset codes
export const RESETCODE_START = 'RESETCODE_START';
export const RESETCODE_SUCCESS = 'RESETCODE_SUCCESS';
export const RESETCODE_FAILURE = 'RESETCODE_FAILURE';

// request reset codes
export const DEMO_START = 'DEMO_START';
export const DEMO_SUCCESS = 'DEMO_SUCCESS';
export const DEMO_FAILURE = 'DEMO_FAILURE';

// reset password codes
export const RESETPASSWORD_START = 'RESETPASSWORD_START';
export const RESETPASSWORD_SUCCESS = 'RESETPASSWORD_SUCCESS';
export const RESETPASSWORD_FAILURE = 'RESETPASSWORD_FAILURE';

// request reset codes
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

// reset password codes
export const CONFIRMREGISTER_START = 'CONFIRMREGISTER_START';
export const CONFIRMREGISTER_SUCCESS = 'CONFIRMREGISTER_SUCCESS';
export const CONFIRMREGISTER_FAILURE = 'CONFIRMREGISTER_FAILURE';

// user profile action types 
export const USER_START = 'USER_START';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';
export const USER_SHOW_CHANGE_PASSWORD = "SHOW_CHANGE_PASSWORD";

// user personalization action types 
export const PERSONALIZATION_START = 'PERSONALIZATION_START';
export const PERSONALIZATION_SUCCESS = 'PERSONALIZATION_SUCCESS';
export const PERSONALIZATION_FAILURE = 'PERSONALIZATION_FAILURE';

// corporate settings action types 
export const SETTINGS_START = 'SETTINGS_START';
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const SETTINGS_FAILURE = 'SETTINGS_FAILURE';

// company profile action types 
export const COMPANY_START = 'COMPANY_START';
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';
export const COMPANY_FAILURE = 'COMPANY_FAILURE';

// partner action types 
export const PARTNER_START = 'PARTNER_START';
export const PARTNER_SUCCESS = 'PARTNER_SUCCESS';
export const PARTNER_REMOVE = 'PARTNER_REMOVE';
export const PARTNER_FAILURE = 'PARTNER_FAILURE';

// tenant action types 
export const TENANT_START = 'TENANT_START';
export const TENANT_SUCCESS = 'TENANT_SUCCESS';
export const TENANT_REMOVE = 'TENANT_REMOVE';
export const TENANT_FAILURE = 'TENANT_FAILURE';

// interaction action types 
export const INTERACTION_START = 'INTERACTION_START';
export const INTERACTION_SUCCESS = 'INTERACTION_SUCCESS';
export const INTERACTION_FAILURE = 'INTERACTION_FAILURE';
export const INTERACTION_REMOVE = 'INTERACTION_REMOVE';

// buidling action types 
export const BUILDING_START = 'BUILDING_START';
export const BUILDING_SUCCESS = 'BUILDING_SUCCESS';
export const BUILDING_REMOVE = 'BUILDING_REMOVE';
export const BUILDING_FAILURE = 'BUILDING_FAILURE';

// unit action types 
export const UNIT_START = 'UNIT_START';
export const UNIT_SUCCESS = 'UNIT_SUCCESS';
export const UNIT_REMOVE = 'UNIT_REMOVE';
export const UNIT_FAILURE = 'UNIT_FAILURE';

// proposal action types 
export const PROPOSAL_START = 'PROPOSAL_START';
export const PROPOSAL_SUCCESS = 'PROPOSAL_SUCCESS';
export const PROPOSAL_REMOVE = 'PROPOSAL_REMOVE';
export const PROPOSAL_FAILURE = 'PROPOSAL_FAILURE';
export const PROPOSAL_LIMITS = 'PROPOSAL_LIMITS';

// proposal unit action types 
export const PROPOSAL_UNIT_START = 'PROPOSAL_UNIT_START';
export const PROPOSAL_UNIT_SUCCESS = 'PROPOSAL_UNIT_SUCCESS';
export const PROPOSAL_UNIT_REMOVE = 'PROPOSAL_UNIT_REMOVE';
export const PROPOSAL_UNIT_FAILURE = 'PROPOSAL_UNIT_FAILURE';
export const PROPOSAL_UNIT_CLEAR = 'PROPOSAL_UNIT_CLEAR';

// proposal history types 
export const PROPOSAL_HISTORY_START = 'PROPOSAL_HISTORY_START';
export const PROPOSAL_HISTORY_SUCCESS = 'PROPOSAL_HISTORY_SUCCESS';
export const PROPOSAL_HISTORY_FAILURE = 'PROPOSAL_HISTORY_FAILURE';
export const PROPOSAL_HISTORY_CLEAR = 'PROPOSAL_HISTORY_CLEAR';

// artifact (aka dossier) action types 
export const ARTIFACT_START = 'ARTIFACT_START';
export const ARTIFACT_SUCCESS = 'ARTIFACT_SUCCESS';
export const ARTIFACT_REMOVE = 'ARTIFACT_REMOVE';
export const ARTIFACT_FAILURE = 'ARTIFACT_FAILURE';

// document/file action types 
export const DOCUMENT_START = 'DOCUMENT_START';
export const DOCUMENT_SUCCESS = 'DOCUMENT_SUCCESS';
export const DOCUMENT_FAILURE = 'DOCUMENT_FAILURE';
export const DOCUMENT_END = 'DOCUMENT_END';

// audit action types 
export const AUDIT_START = 'AUDIT_START';
export const AUDIT_SUCCESS = 'AUDIT_SUCCESS';
export const AUDIT_FAILURE = 'AUDIT_FAILURE';

// notifications action types 
export const NOTIFICATION_START = 'NOTIFICATION_START';
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_FAILURE = 'NOTIFICATION_FAILURE';

// addons action types 
export const ADDON_START = 'ADDON_START';
export const ADDON_SUCCESS = 'ADDON_SUCCESS';
export const ADDON_FAILURE = 'ADDON_FAILURE';

// addons action types 
export const ENQUIRY_START = 'ENQUIRY_START';
export const ENQUIRY_SUCCESS = 'ENQUIRY_SUCCESS';
export const ENQUIRY_FAILURE = 'ENQUIRY_FAILURE';

// addons action types 
export const LIBRARY_START = 'LIBRARY_START';
export const LIBRARY_SUCCESS = 'LIBRARY_SUCCESS';
export const LIBRARY_FAILURE = 'LIBRARY_FAILURE';

// local notices/messages to the user
export const NOTICE_SHOW = "NOTICE_SHOW";
export const NOTICE_HIDE = "NOTICE_HIDE";

// search related types
export const BUILDING_SEARCH = "BUILDING_SEARCH";

export const BREADCRUM_SHOW = "BREADCRUM_SHOW";
export const BREADCRUM_UPDATE = "BREADCRUM_UPDATE";

export const TOOLBAR_STATE = "TOOLBAR_STATE";
export const TOOLBAR_UPDATE = "TOOLBAR_UPDATE";

// misc action types
export const REDIRECT_PATH = 'REDIRECT_PATH';

export * from "common/store/types";