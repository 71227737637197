import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import LockOutline from "@material-ui/icons/LockOutline";
import classNames from "classnames";

// Sections for this page
import BackgroundSection from "../components/Section/BackgroundSection";
import SectionTop from "./Sections/SectionTop";
import SectionBottom from "./Sections/SectionBottom";

import { useTitle } from "variables/utilities";

import style from "assets/jss/material-dashboard-pro-react/views/website/landingPageStyle.js";
const useStyles = makeStyles(style);

export default function Technology({ ...rest }) {
  useTitle("Exaspaces platform, a reliable, secure, flexible and extensible platform for the real estate indusutry");

  const classes = useStyles();
  return (
    <div>
      <BackgroundSection
        {...rest}
        className={classes.backgroundTechnology}
        image={require("assets/img/technology/technology-background.png")} 
        title="Here is what’s in store to make the Exaspaces platform a whole lot effortless"
        subtitle="Being secure, scalable, flexible and extensible, the platform is straightforward for small 
        and large enterprises alike."
      />
      <div className={classNames(classes.main, classes.main)}>
        <div className={classes.striped}>
          <div className={classes.container}>
            <SectionTop />
          </div>
        </div>
        <div className={classes.container}>
          <SectionBottom />
        </div>
      </div>
    </div>
  );
}
