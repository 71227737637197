// Imports
import React, { Component } from 'react';
import { connect } from 'react-redux';

// echelon components
import WebLayoutView from './WebLayoutView';

class WebLayout extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return <WebLayoutView {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebLayout);
