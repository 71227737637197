import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import LockOutline from "@material-ui/icons/LockOutline";
import classNames from "classnames";

// Sections for this page
import BackgroundSection from "views/Website/components/Section/BackgroundSection";
import SectionTop from "./Sections/SectionTop";
import SectionBottom from "./Sections/SectionBottom";

import style from "assets/jss/material-dashboard-pro-react/views/website/landingPageStyle.js";
const useStyles = makeStyles(style);

export default function FastTimeValue({ ...rest }) {

  const classes = useStyles();
  return (
    <div>
      <BackgroundSection
        {...rest}
        marginTop={false}
        className={classes.backgroundFastTimValue}
        image={require("assets/img/landing/section1-image1.webp")} 
        title="Create value for your clients"
        subtitle="A complete, intelligent proposal management system with embedded recommendations and collaboration."
        crumbs={[{crumbname: "Broker platform", crumbpath: "/website/platform"}, {crumbname: "Create value for your clients"}]}
      />
      <div className={classNames(classes.main, classes.main)}>
        <div className={classes.striped}>
          <div className={classes.container}>
            <SectionTop />
          </div>
        </div>
        <div className={classes.container}>
          <SectionBottom />
        </div>
      </div>
    </div>
  );
}
