
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import enquiry from 'store/reducers/enquiry';
import register from 'store/reducers/register';
import registerconfirm from  'store/reducers/registerconfirm';
import demo from 'store/reducers/demo';
import notice from 'store/reducers/notice';

const reducers = combineReducers({
  notice: notice,
  enquiry: enquiry,
  register: register,
  registerconfirm: registerconfirm,
  demo: demo,
});

const rootreducer = (state, action) => {
  if(action.type === "AUTHORIZE_LOGOUT") {
    state = undefined;
  }

  return reducers(state, action);
}

let composeEnhancers = null;
if (process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
    composeEnhancers = compose;
}

const store = createStore(rootreducer, composeEnhancers(
  applyMiddleware(thunk)
));

export default function createStores() {
    return (store);
}