import React, { Fragment } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import LockOutline from "@material-ui/icons/LockOutline";
import classNames from "classnames";

// Sections for this page
import BackgroundSection from "../components/Section/BackgroundSection";
import Product from "./Sections/Product.js";

import { useTitle } from "variables/utilities";

import styles from "assets/jss/material-dashboard-pro-react/views/website/landingPageStyle";
const useStyles = makeStyles(styles);

export default function LandingPage({ ...rest }) {
  useTitle("Exaspaces, a commercial real estate platform for brokers");
  
  const classes = useStyles();
  return (
    <div>
      <BackgroundSection
        {...rest}
        landing
        className={classes.backgroundLanding}
        image={require("assets/img/landing/landing-background.webp")} 
        title="Embrace the future of Commercial Real Estate"
        subtitle={<Fragment>The World’s #1 comprehensive suite of products you need to <b>run and transform</b> your business forever. 
        With the <b>Exaspaces Platform</b>, you get the foundation to <b>Streamline, Optimize and 
        Accelerate</b> your business.</Fragment>}
      />
      <div className={classNames(classes.main, classes.main)}>
        <div className={classes.container}>
          <Product />
        </div>
      </div>
    </div>
  );
}
