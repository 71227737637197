/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// core components
import Button from "core/components/CustomButtons/Button.js";

import styles from "core/assets/jss/material-dashboard-pro-react/components/landingHeaderLinksStyle";
import { Link } from "react-router-dom";
import { Fragment } from "react";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const { history, items=[], localsignin=false, 
          signin="/broker/signin", demo="/website/demo" } = props;

  const selectedListItem = (path) => {
    return history.location.pathname.startsWith(path) ? 
            classes.listItem + " " + classes.selectedListItem : 
            classes.listItem;
  }

  const selectedListItemText = (path) => {
    return history.location.pathname.startsWith(path) ? 
            classes.selectedListItemText : 
            classes.listItemText;
  }

  const classes = useStyles();
  return (
    <Fragment>
      <List className={classes.list + " " + classes.mlAuto}>
        {items.map((item, key) => {
          const { to, text } = item;
          return (
            <ListItem 
              key={key}
              className={selectedListItem(to)} 
              onClick={() => {history.push(to)}} >
              <ListItemText
                className={selectedListItemText(to)} 
                primary={<Link className={classes.linkText} to={to}>{text}</Link>} 
              />
            </ListItem>
          );
        })}
      </List>
      {!localsignin &&
      <Button
        color={"rose"}
        href={signin}
        rel="noopener noreferrer"
        className={classes.navButton}
      >
        Sign In
      </Button>
      }
      {localsignin &&
      <Button
        color={"rose"}
        onClick={() => {history.push(signin)}}
        className={classes.navButton}
      >
        Sign In
      </Button>
      }
      {demo &&
      <Button
        color={"purple"}
        onClick={() => {history.push(demo)}}
        className={classes.navButton}
      >
        Get a demo
      </Button>
      }
      </Fragment>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
