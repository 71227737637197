import React from "react";

// echelon components and icons
import InfoSection from "views/Website/components/Section/InfoCardSection";

import adminImage from "assets/img/technology/section1-admin.svg";
import markedPlaceImage from "assets/img/technology/section1-market.svg";
import userManagementImage from "assets/img/technology/section1-um.svg";

export default function SectionTop(props) {
  return (
    <InfoSection
      title={"Configure and manage the platform with little to no effort; sit back, relax and enjoy the Exaspaces experience"}
      items={[
        {
          image: adminImage,
          title: "Administration",
          description: "Corporate settings and company profile that govern the business workflows"
        },
        {
          image: userManagementImage,
          title: "User management",
          description: "Manage lifecycle of users and associated roles/authorization to access various features and functions of the platform"
        },
        {
          image: markedPlaceImage,
          title: "Add-on marketplace",
          description: "Pick and choose feature add-ons based on customer’s business requirements; flexible and comprehensive at the same time"
        },
      ]}
    />
  );
}