const infoCardStyles = theme => ({
  cardBody: {
    textAlign:"justify",
    fontWeight: "400",
    fontSize: "1.2em",
    minHeight: "108px",
    [theme.breakpoints.only("md")]: {
      minHeight: "180px",
    }
  },
  image: {
    width: "57px",
    height: "57px",
  },
  title: {
    marginLeft: "0px",
    fontSize: "2.0em",
    fontWeight: "500",
    lineHeight: ".8em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "12px",
      fontSize: "2.2em",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
      fontSize: "2.0em",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "12px",
      fontSize: "2.2em",
    },
  },
  titleStackedCard: {
    fontWeight: "700",
    textAlign: "center",
    fontSize: "1.8em",
    lineHeight: "1.2em",
  },
  cardBodyStacked: {
    textAlign:"center",
    fontWeight: "400",
    fontSize: "1.2em",
    minHeight: "108px",
    [theme.breakpoints.only("md")]: {
      minHeight: "180px",
    }
  },

});

export default infoCardStyles;