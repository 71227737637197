import React, { Component } from 'react';
import { connect } from 'react-redux';

//echelon components
import RegisterView from "views/Website/Entry/RegisterView"
import ResultView from "../ResultView";

// echelon commons/utilities
import { updatestate } from 'variables/utilities';

// state management and actions 
import { register } from 'store/actions/register';
import { confirm } from 'store/actions/registerconfirm';
import * as notice from "store/actions/notice";

class Register extends Component {

  // state related data container
  state = {
      basic: {
        email: '',
        name: '',
        city: '',
        address: { locality: '' },
        password: '',
        code: '',

        createdByName: '',
        category: [],
        phoneNo: '',
      },
      valid: {},

      showotp: false,
      business: undefined,
      businessModifiedDate: -1,

      modifiedDate: -1,
      error: null,
  }

  static getDerivedStateFromProps(props, state) {
    const { business, error, modifiedDate } = props;

    // backend accepted the request for enrolment
    if(business && business.modifiedDate > state.businessModifiedDate) {
      return {
        business: business,
        businessModifiedDate: business.modifiedDate,
        showotp: true,
        modifiedDate: modifiedDate,
        error: error,
      }
    }

    // manage backend errors in the registration flow
    if(modifiedDate > state.modifiedDate) {
      return {
        modifiedDate: modifiedDate,
        error: error,
      }
    }

    return null;
  }
 
  onChange = (event, attribute, type, valid=true) => {
		this.setState(updatestate(this.state, event, attribute, type, valid));
  }
  
  register = (token, purpose, version) => {
    const { basic } = this.state;
    const body = {
      name: basic.name,
      createdBy: basic.email,
      address: [basic.address],
      traits: {
        token: token,
        purpose: purpose,
        version: version,

        createdByPhone: basic.phoneNo,
        createdByName: basic.createdByName,
        category: basic.category,
        freetrial: this.props.freetrial,
      }
    };

    this.props.register(body);
  }

  confirm = (token, purpose, version) => {
    const { basic, business } = this.state;
    const body = {
      businessId: business.businessId,
      otp: basic.code,
      password: basic.password,
      token: token,
      purpose: purpose,
      version: version,
    };  

    this.props.confirm(business.businessId, body);
  }

  onSubmit = ({ token, purpose, version }) => {
		// perform the necessary validation and show the error message if required
    const valid = this.validate();
    const proceed = Object.values(valid).find((value) => value === false);
		if(proceed === false) {
      this.setState({valid: valid});
			this.props.notify("999");
			
			return;
		}

    const { business } = this.state;
    if(!business) { // need to perform regisration
      this.register(token, purpose, version);
    }

    if(business) { // need to confirm the request with otp
      this.confirm(token, purpose, version);
    }
  }

  validate = () => {
		const { basic, valid } = this.state;
		if(basic.name === '') {
			valid.name = false;
		}
    if(basic.email === '') {
			valid.email = false;
		}
		if(basic.password === '') {
			valid.password = false;
		}
		if(basic.address.locality === '') {
			valid.address = false;
		}
    
		return valid;			
  }
 
  // the view of the component that needs to be rendered
  render () {
    const { error } = this.state;

    // show we show the results view?
    if(this.props.showresult) {
      return (
        <ResultView
            {...this.props}
            dashboard={false}
            title="Register with Echelon"
            infotitle="Registration request submitted"
            infosubtitle="Your registration request has been submitted successfully and we are working on it, please stay tuned for the next steps!"
            onChangeHandler={this.onChange}
            onSubmitHandler={this.onSubmit}
        />
      );
    }

    // show the register view
    const { basic, valid, showotp } = this.state;
    return (
      <RegisterView
        {...this.props}
        manualcaptcha={["600", "601", "602"].includes(error)}
        showotp={showotp}
        basic={basic}
        valid={valid}
        onChangeHandler={this.onChange}
        onSubmitHandler={this.onSubmit}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    modifiedDate: state.register.modifiedDate,
    error: state.register.error,
    business: state.register.business,
    showresult: state.registerconfirm.showresult,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    notify: (code) => dispatch(notice.notifyFailure(code)),
    register: (body) => dispatch(register(body)),
    confirm: (businessId, body) => dispatch(confirm(businessId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
