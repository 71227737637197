import * as types from "store/types.js";
import { update } from 'variables/utilities';

const initial = {
  request: null,
  loading: false, error: null
};
  
const started = (state, action) => {
  return update( state, { 
    request: action.request,
    error: null, loading: true,
    modifiedDate: -1,
  });
};

const succeeded = (state, action) => {
  return update( state, {
    demo: action.demo,
    error: null, loading: false,
    modifiedDate: new Date(),    
  });
};

const failed = (state, action) => {
  return update( state, {
    error: action.error, loading: false,
    modifiedDate: new Date(),
  });
}

const reducer = (state = initial, action) => {
  switch ( action.type ) {
    case types.DEMO_START: 
      return started(state, action);

    case types.DEMO_SUCCESS: 
      return succeeded(state, action);

    case types.DEMO_FAILURE: 
      return failed(state, action);

    default:
      return state;
  }
};

export default reducer;