import {
  blackColor,
} from "core/assets/jss/material-dashboard-pro-react.js";

const infoCardSectionStyles = theme => ({
  section: {
    color: blackColor,
    marginTop: "48px",
  },
  title: {
    textAlign: "center",
    fontSize: "1.995em",
    fontWeight: "700",
    marginLeft: "12%",
    marginRight: "12%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "4%",
      marginRight: "4%",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.795em",
      marginLeft: "8%",
      marginRight: "8%",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "12%",
      marginRight: "12%",
    },

  }
});

export default infoCardSectionStyles;