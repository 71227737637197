import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "core/components/Grid/GridContainer";
import GridItem from "core/components/Grid/GridItem";

// echelon components
import InfoCard from "views/Website/components/InfoCard/InfoCard";

// images for the section
import clientImage from "assets/img/vision/section1-clients.svg";
import brokerImage from "assets/img/vision/section1-brokers.svg";
import ownersImage from "assets/img/vision/section1-owners.svg";
import whyExaspacesImage from "assets/img/platform/platform_section1.webp";

import styles from "assets/jss/material-dashboard-pro-react/views/website/whyExaspaces.js";
const useStyles = makeStyles(styles);

export default function ExploreExaspaces(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.underline}>
        <span className={classes.text}>Who can use</span>
        <span className={classes.colortext}>{` EXASPACES `}</span>
        <span className={classes.text}>?</span>
      </div>

      <div className={classes.container}
            style={{backgroundImage: "url(" + whyExaspacesImage + ")" ,
                    backgroundPosition: "center", paddingTop: "16px",
                    paddingLeft: "16px", paddingRight: "16px"}}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <InfoCard
              icon
              raised
              image={brokerImage}
              header={"Property brokers"}
              contents={"Property consultants / brokers in India dealing with any and all types of Office, Residential, Retail, Land, Warehouse and Industrial, Co-working and Business Centre properties for rent or lease, sale, purchase or investment purposes."}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InfoCard
              raised
              image={ownersImage}
              header={"Owners"}
              contents={"Owners, Builders, Developers of Office, Residential, Retail, Industrial, Warehouse, Land, Co-working properties needing assistance to sell, buy, lease or rent your assets, we help connect you to the right channel that will deliver results for you."}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InfoCard
              raised
              image={clientImage}
              header={"Clients"}
              contents={"Get the right real estate solution you seek and find the best real estate people to do that for you, for any of your real estate needs be it Office, Residential, Retail, Industrial, Warehouse, Land, Co-working spaces and Business Centers."}
            />
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.bottomUnderline} />
    </div>
  );
}