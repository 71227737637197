import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Card from "core/components/Card/Card";
import CardBody from "core/components/Card/CardBody";
import CardHeader from "core/components/Card/CardHeader";
import CardFooter from "core/components/Card/CardFooter";

import visionSectionStyles from "assets/jss/material-dashboard-pro-react/components/infoCardStyles";
const useStyles = makeStyles(visionSectionStyles);

export default function StackedInfoCard (props) {
  const { image, title, description } = props;

  const classes = useStyles();
  return (
    <Card plain>
      <CardHeader centered>
        <img src={image} className={classes.image} alt={description}/>
      </CardHeader>
      <CardBody>
        <h2 className={classes.titleStackedCard}>{title}</h2>
        <div className={classes.cardBodyStacked}>{description}</div>
      </CardBody>
      <CardFooter />
    </Card>
  )
}
