import {
  container,
  title,
  main,
  whiteColor,
  grayColor,
  mainRaised
} from "core/assets/jss/material-dashboard-pro-react.js";

const landingPageStyle = theme => ({
  container: {
    color: whiteColor,
    ...container,
    zIndex: "2"
  },
  striped: {
    color: whiteColor,
    backgroundColor: grayColor[8],
    zIndex: "2"
  },  
  backgroundLanding: {
    maxHeight: "680px",
    "&:before": {
      background: "linear-gradient(90deg, rgba(0, 0, 0, 0.42) 12.01%, rgba(0, 0, 0, 0.09) 77.43%)"
    },
  },
  backgroundVision: {
    maxHeight: "560px",
    "&:before": {
      background: "linear-gradient(90deg, rgba(0, 0, 0, 0.53) 31.28%, rgba(0, 0, 0, 0) 100%)"
    },
  },
  backgroundPlatform: {
    maxHeight: "560px",
    [theme.breakpoints.down("md")]: {
      minHeight: "560px",
      maxHeight: "840px",
    },
    "&:before": {
      background: "linear-gradient(90deg, rgba(0, 0, 0, 0.46) 9.38%, rgba(0, 0, 0, 0) 89.62%)"
    },
  },
  backgroundTechnology: {
    maxHeight: "560px",
    [theme.breakpoints.down("md")]: {
      minHeight: "560px",
      maxHeight: "720px",
    },
   "&:before": {
      background: "linear-gradient(90deg, rgba(0, 0, 0, 0.69) 28.58%, rgba(0, 0, 0, 0) 88.23%);"
    },
  },
  backgroundImplementation: {
    maxHeight: "560px",
    [theme.breakpoints.down("md")]: {
      minHeight: "560px",
      maxHeight: "720px",
    },
  "&:before": {
      background: "linear-gradient(90deg, rgba(0, 0, 0, 0.87) 10.15%, rgba(0, 0, 0, 0.3) 78.23%)"
    },
  },
  backgroundFastTimValue: {
    marginTop: "80px",
    maxHeight: "540px",
    "&:before": {
      background: "linear-gradient(90deg, rgba(0, 0, 0, 0.64) 8.68%, rgba(0, 0, 0, 0) 92.85%)"
    },
  },
  backgroundDataCapture: {
    marginTop: "80px",
    maxHeight: "540px",
    "&:before": {
      background: "linear-gradient(90deg, rgba(0, 0, 0, 0.69) 22.99%, rgba(0, 0, 0, 0.0414) 89.72%)"
    },
  },
  backgroundEngagement: {
    marginTop: "80px",
    maxHeight: "540px",
    "&:before": {
      background: "linear-gradient(90deg, rgba(0, 0, 0, 0.69) 24.2%, rgba(0, 0, 0, 0) 94.65%)"
    },
  },
  backgroundTransaction: {
    marginTop: "80px",
    maxHeight: "540px",
    "&:before": {
      background: "linear-gradient(90deg, rgba(0, 0, 0, 0.69) 5.31%, rgba(0, 0, 0, 0) 90.52%)"
    },
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    color: whiteColor,
    minHeight: "32px",
    fontWeight: "600",
    textDecoration: "none",
    [theme.breakpoints.up("md")]: {
      marginTop: "96px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "72px",
      maxWidth: "70%",
    },
  },
  subtitle: {
    marginTop: "-16px",
    fontSize: "1.1rem",
    fontWeight: "400",
    maxWidth: "500px",
    lineHeight: "1.5rem"
  },
  main: {
    ...main
  },
  mainRaised: {
    ...mainRaised
  },
  exploreMargin: {
    paddingBottom: "16px",
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  textBold: {
    zIndex: 5,
    fontWeight: "700"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  }
});

export default landingPageStyle;
