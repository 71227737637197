import React, { Fragment } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import LockOutline from "@material-ui/icons/LockOutline";
import classNames from "classnames";

// Sections for this page
import BackgroundSection from "../components/Section/BackgroundSection";
import InfoDetailSection from "../components/Section/InfoDetailSection";
import WhyExaspaces from "./Broker/WhyExaspaces";

import sectionBackgroundImage1 from "assets/img/platform/section-background.webp";

import sectionImage1 from "assets/img/landing/section1-image1.webp";
import sectionImage2 from "assets/img/landing/section1-image2.webp";
import sectionImage3 from "assets/img/landing/section1-image3.webp";
import sectionImage4 from "assets/img/platform/platform_marketplace.webp";
import trackClientImage from "assets/img/platform/platform_section2.webp";
import closeDealsImage from "assets/img/platform/platform_section_deals.webp";

import { useTitle } from "variables/utilities";

import styles from "assets/jss/material-dashboard-pro-react/views/website/landingPageStyle.js";
const useStyles = makeStyles(styles);

export default function BrokerPlatform({ ...rest }) {
  useTitle("Broker platform, automate your real estate proposal management");

  const classes = useStyles();
  return (
    <div>
      <BackgroundSection
        {...rest}
        className={classes.backgroundPlatform}
        image={require("assets/img/platform/platform-background.webp")} 
        title="Close more deals with Exaspaces, the exclusive platform for Property Consultants"
        subtitle={<div>India’s best one-stop SaaS platform for real estate professionals to run your brokerage business. 
                  Exaspaces helps you to close more deals, save time, costs and manhours.<br></br><br></br>
                  Built to create value for your brokerage business.</div>
                }
        buttonText="Start your free trial"
        color="info"
        url="/website/freetrial/signup"
      />
      <div className={classes.main}>
        <div className={classes.container}>
          <WhyExaspaces />
        </div>

        <div className={classes.striped}>
          <InfoDetailSection
            title="Gain positive Customer Experience to acquire & retain customers"
            description="Give your Clients a great user experience with our two-way communication platform that offers accuracy, speed, convenience and knowledgeable information that will aid in better decision making."
            image={sectionImage1}
            url={"/website/platform/value"}
            {...rest}
          />
        </div>
        <div className={classes.container}>
          <div className={classes.container}>
            <InfoDetailSection
              swap
              position="left bottom"
              title="Managing and sharing your property database made easy"
              description={<Fragment>Comprehensive information management on properties for Residential, Office, Retail, Land & Industrial, Co-working and Business Centre. <br></br><br></br>You can share the property information with your clients in just a few clicks.</Fragment>}
              image={sectionImage3}
              imageBackground={sectionBackgroundImage1}
              url={"/website/platform/transaction"}
              {...rest}
            />
          </div>
        </div>
        <div className={classes.striped}>
          <InfoDetailSection
            title="Track your client meetings"
            description="With our interaction’s module, you and your team members will be alerted to every appointment. Track your discussions with your client and never miss a conversation. Build better knowledge of your client and collaborate better with your teams and your clients."
            image={trackClientImage}
            url={"/website/platform/engagement"}
            {...rest}
          />
        </div>
        <div className={classes.container}>
          <div className={classes.container}>
            <InfoDetailSection
              swap
              position="right bottom"
              title="Enquiry to Deal closure, keep them at your finger tips"
              description="Be up to speed at all times and keep the most important aspects of your business at your finger tips and never miss an opportunity to close more deals."
              image={closeDealsImage}
              textBackground={sectionBackgroundImage1}
              {...rest}
            />
          </div>
        </div>
        <div className={classes.striped}>
          <InfoDetailSection
            title="Fix your single BIGGEST CHALLENGE, convert Enquiry to Deals"
            description={<Fragment>Most brokers spend a lot of their time, money, and energy on generating leads through multiple avenues, 
              yet face very high failure rate and very low conversion rates; this leads to high frustration levels.
              <br></br><br></br>Get to know our best kept secret to help you close more deals. 
              </Fragment>}
            image={sectionImage2}
            {...rest}
          />
        </div>
        <div className={classNames(classes.container, classes.exploreMargin)}>
          <InfoDetailSection
            swap
            position="right bottom"
            title="Built for all business sizes & any brokerage verticals"
            description={<Fragment>
                            From a single person to very large businesses. From Individual proprietor, partnership firms to large private companies; 
                            from single-city single line of business, single-city multi-vertical, multi-city single-vertical to multi-city 
                            multi-vertical property consulting business. 
                            <br></br><br></br>Exaspaces features & functions are built with flexibility, 
                            scalability and redundancy/adaptability in mind. 
                        </Fragment>}
            image={sectionImage4}
            textBackground={sectionBackgroundImage1}
            {...rest}
          />
        </div>
      </div>
    </div>
  );
}
