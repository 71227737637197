import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import whoareweSectionStyles from "assets/jss/material-dashboard-pro-react/views/website/whoareweSectionStyles.js";
const useStyles = makeStyles(whoareweSectionStyles);

export default function WhoAreWe(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
    </div>
  );
}