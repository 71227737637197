import React from "react";

// echelon components and icons
import InfoSection from "views/Website/components/Section/InfoCardSection";

import dataServiceImage from "assets/img/implementation/section1-data.svg";
import trainingServicesImage from "assets/img/implementation/section1-training.svg";
import consultingImage from "assets/img/implementation/section1-consulting.svg";

export default function SectionTop(props) {
  return (
    <InfoSection
      title={"Whether it’s getting acquainted with the platform or it’s about getting your data into the platform or expert advisory, we have an answer!"}
      items={[
        {
          image: dataServiceImage,
          title: "Data services",
          description: "We help you bring your data into Exaspaces, and we enable you to get started with Exaspaces almost instantly"
        },
        {
          image: trainingServicesImage,
          title: "Training services",
          description: "We are here to offer you a comprehensive training service to smoothen your journey to adopt Exaspaces"
        },
        {
          image: consultingImage,
          title: "Consulting and Industry best practices",
          description: "We share our industry experience, and we help you to embrace best practices for accelerated ROI"

        },
      ]}
    />
  );
}