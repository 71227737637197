// @material-ui/icons

import Register from "views/Website/Entry/Register";
import LandingPage from "views/Website/LandingPage/LandingPage";
import BrokerPlatform from "views/Website/Platform/BrokerPlatform";
import Technology from "views/Website/Technology/Technology";
import Implementation from "views/Website/Implementation/Implementation";
import Vision from "views/Website/Vision/Vision";
import FastTimeValue from "views/Website/Platform/Broker/FastTimeValue/FastTimeValue";
import DataCapture from "views/Website/Platform/Broker/DataCapture/DataCapture";
import Engagement from "views/Website/Platform/Broker/Engagement/Engagement";
import Transaction from "views/Website/Platform/Broker/Transaction/Transaction";
import Demo from "views/Website/Entry/Demo/Demo";

var webRoutes = [
  {
    path: "/demo",
    component: Demo,
    layout: process.env.REACT_APP_EXASPACES_WEBSITE
  },
  {
    path: "/freetrial/signup",
    component: Register,
    props: { freetrial: true },
    layout: process.env.REACT_APP_EXASPACES_WEBSITE
  },
  {
    path: "/signup",
    component: Register,
    layout: process.env.REACT_APP_EXASPACES_WEBSITE
  },
  {
    path: "/vision",
    component: Vision,
    layout: process.env.REACT_APP_EXASPACES_WEBSITE
  },
  {
    path: "/platform/engagement",
    component: Engagement,
    layout: process.env.REACT_APP_EXASPACES_WEBSITE
  },
  {
    path: "/platform/transaction",
    component: Transaction,
    layout: process.env.REACT_APP_EXASPACES_WEBSITE
  },
  {
    path: "/platform/datacapture",
    component: DataCapture,
    layout: process.env.REACT_APP_EXASPACES_WEBSITE
  },
  {
    path: "/platform/value",
    component: FastTimeValue,
    layout: process.env.REACT_APP_EXASPACES_WEBSITE
  },
  {
    path: "/platform",
    component: BrokerPlatform,
    layout: process.env.REACT_APP_EXASPACES_WEBSITE
  },
  {
    path: "/technology",
    component: Technology,
    layout: process.env.REACT_APP_EXASPACES_WEBSITE
  },
  {
    path: "/implementation",
    component: Implementation,
    layout: process.env.REACT_APP_EXASPACES_WEBSITE
  },
  {
    path: "/landing",
    component: LandingPage,
    layout: process.env.REACT_APP_EXASPACES_WEBSITE
  },
  {
    path: "",
    component: BrokerPlatform,
    layout: process.env.REACT_APP_EXASPACES_WEBSITE
  },
];
export default webRoutes;
