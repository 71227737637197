import {
  defaultFont,
  container,
  containerFluid,
  primaryColor,
  whiteColor,
  grayColor,
  linkedinColor,
  facebookColor,
  twitterColor,
  purpleColor
} from "core/assets/jss/material-dashboard-pro-react.js";

const footerStyle = theme => ({
  block: {},
  left: {
    float: "left!important",
    display: "block",
    marginTop: "6px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "75%",
    }
  },
  leftText: {
    padding: "15px",
    fontSize: "16px",
    fontWeight: "500",
    [theme.breakpoints.up("md")]: {
      maxWidth: "40%",
    },
  },
  right: {
    margin: "0",
    fontSize: "14px",
    padding: "0px",
    [theme.breakpoints.up("md")]: {
      float: "right !important",
      maxWidth: "25%",
    }
  },
  websiteright: {
    margin: "0",
    fontSize: "14px",
    padding: "15px",
    paddingTop: "0px",
    [theme.breakpoints.up("md")]: {
      float: "right !important",
      maxWidth: "25%",
    }
  },
  logo: {
    height: "32px",
  },
  websitelogo: {
    height: "48px",
    [theme.breakpoints.up("md")]: {
      height: "64px"  
    },
  },
  linkedinIcon: {
    color: linkedinColor
  },
  facebookIcon: {
    color: facebookColor
  },
  twitterIcon: {
    color: twitterColor
  },
  buttonContainer: {
    padding: "0 !important",
    margin: "0 !important"
  },
  buttonIcon: {
    fontWeight: "300",
    fontSize: "32px !important",
    color: purpleColor[0],
  },
  buttonText: {
    textTransform: "none",
    fontSize: "1.0rem",
  },
  footer: {
    bottom: "0",
    borderTop: "2px solid " + grayColor[15],
    ...defaultFont,
    padding: "6px 0",
    zIndex: 4
  },
  websitefooter: {
    bottom: "0",
    borderTop: "1px solid " + grayColor[15],
    ...defaultFont,
    zIndex: 4
  },  
  container: {
    zIndex: 3,
    ...container,
    marginLeft: "none",
    position: "relative"
  },
  containerFluid: {
    zIndex: 1,
    ...containerFluid,
    position: "relative"
  },
  a: {
    color: primaryColor[0],
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0",
    width: "auto"
  },
  whiteColor: {
    "&,&:hover,&:focus": {
      color: whiteColor
    }
  }
});

export default footerStyle;
