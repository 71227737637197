import axios from "store/axios-broker";
import * as types from "store/types.js";
import { show as notifySuccess } from "store/actions/notice";

const started = (request) => {
  return {
    type: types.ENQUIRY_START,
    request: request
  };
};

const succeeded = () => {
  return {
    type: types.ENQUIRY_SUCCESS,
  };
};

const failed = (error) => {
  return {
    type: types.ENQUIRY_FAILURE,
    error: error && error.data && error.data.code ? error.data.code : "000"
  };
};

const querystring = require('querystring');
export const enquire = (body) => {
  return dispatch => {
    dispatch(started('enquiry'));
    axios.post("/apex/business/enroll/enquiry",
                querystring.stringify(body), { urlencoded: true }
    ).then(response => {
      dispatch(succeeded());
			dispatch(notifySuccess("enquirysuccess", "primary"));
    }).catch(error => {
      dispatch(failed(error.response));
    });
  };
};